import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [];

export const dictionary = {
		"/": [8],
		"/app": [9,[2]],
		"/app/dashboard": [10,[2,3]],
		"/app/dashboard/[accountId]": [11,[2,3,4]],
		"/app/dashboard/[accountId]/crypto-payment": [12,[2,3,4]],
		"/app/dashboard/[accountId]/crypto-top-up": [13,[2,3,4]],
		"/app/dashboard/[accountId]/exchange": [14,[2,3,4]],
		"/app/dashboard/[accountId]/fees": [15,[2,3,4]],
		"/app/dashboard/[accountId]/history-balance": [16,[2,3,4]],
		"/app/dashboard/[accountId]/invite-friend": [17,[2,3,4]],
		"/app/dashboard/[accountId]/issue-new-card": [18,[2,3,4]],
		"/app/dashboard/[accountId]/payment/bank-transfer": [19,[2,3,4,5]],
		"/app/dashboard/[accountId]/payment/payment-card": [20,[2,3,4,5]],
		"/app/dashboard/[accountId]/payment/payment-cash": [21,[2,3,4,5]],
		"/app/dashboard/[accountId]/statements": [22,[2,3,4]],
		"/app/dashboard/[accountId]/top-up": [23,[2,3,4]],
		"/app/dashboard/[accountId]/transactions": [24,[2,3,4]],
		"/app/identification": [25,[2]],
		"/app/identification/fill-individual-questionnaire": [26,[2]],
		"/app/profile": [27,[2]],
		"/app/recipients": [28,[2]],
		"/app/recipients/add-a-recipient": [30,[2]],
		"/app/recipients/[recipientId]": [29,[2]],
		"/app/verification": [31,[2]],
		"/register": [32],
		"/secure": [33],
		"/sign-up-wm": [35,[7]],
		"/sign-up": [34,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';